<template>
  <KTPortlet v-bind:title="'Shipment'">
    <template v-slot:body>
      <div class="row" v-if="loading">
        <div class="col-md-12 mt-2">
          <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
        </div>
      </div>
      <div class="row" v-else-if="!loading && shipments && shipments.length<1">
        <div class="col-md-12 text-center">
          <img src="@/assets/media/icons/no-data-found.png" class="no-data-img">
          <h5 class="mt-10">Looks like you have not made any sales yet. You can get reports once you sell your first product.</h5>
        </div>
      </div>
      <v-row v-else>
        <v-col xs="12" md="12" sm="12" class="text-right">
          <v-btn outlined dense class="m-2" @click.prevent="readyToPickup">Ready to pickup</v-btn>
          <v-btn outlined dense class="m-2" @click.prevent="dispatched">Dispatched</v-btn>
          <v-btn outlined dense class="m-2" @click.prevent="inDelivery">On the way</v-btn>
          <v-btn outlined dense class="m-2" @click.prevent="delivered">Delivered</v-btn>
          <v-btn outlined dense class="m-2" @click.prevent="returned">Returned</v-btn>
        </v-col>
        <v-col xs="12" md="12" sm="12">
          <v-simple-table height="300px">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">#</th>
                  <th class="text-left">Picking Slip No</th>
                  <th class="text-left">Customer Name</th>
                  <th class="text-left">Address</th>
                  <th class="text-left">Shipment Status</th>
                  <th class="text-left">Tracking Code</th>
                  <th class="text-left">Package Weight</th>
                  <!-- <th class="text-left">Calories</th> -->
                </tr>
              </thead>
              <tbody v-if="shipments.length>0">
                <tr v-for="item in shipments" :key="item.name">
                  <td>
                    <v-checkbox
                      v-model="selected"
                      :value="item.id"
                      :disabled="item.shipment_status =='delivered'"
                    ></v-checkbox>
                  </td>
                  <td>{{ item.picking_slip_no }}</td>
                  <td>{{ item.shipping_detail.first_name + " "+item.shipping_detail.last_name }}</td>
                  <td>
                    <span v-if="item.shipping_address">
                      {{
                      ((item.shipping_address.address_line_1)?item.shipping_address.address_line_1+', ':null)+
                      ((item.shipping_address.area)?item.shipping_address.area+', ':null) +
                      ((item.shipping_address.city)?item.shipping_address.city+', ':null)+
                      ((item.shipping_address.state)?item.shipping_address.state:null)
                      }}
                    </span>
                    <span v-else>N/A</span>
                  </td>
                  <td>
                    <span
                      class="badge badge-primary"
                    >{{ item.shipment_status.split("_").join(" ").toUpperCase() }}</span>
                  </td>
                  <td>
                    <span class="badge badge-primary">{{ item.tracking_code }}</span>
                  </td>
                  <td>
                    <span class="badge badge-primary">{{ item.weight }} KG</span>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="12" class="text-center">
                    <h4>No Data found</h4>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
        <pre>
          <!-- {{selected}} -->
    </pre>
      </v-row>
    </template>
  </KTPortlet>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ShipmentService from "@/services/store/ShipmentService";
const shipmentService = new ShipmentService();

export default {
  components: {
    KTPortlet
  },
  data() {
    return {
      loading:true,
      selected: [],
      shipments: [],
      filter: {},
    };
  },
  mounted() {
    this.getShipments();
  },
  watch: {
    $route() {
      this.filter.status = this.$route.params.status;
      this.getShipments();
    }
  },
  methods: {
    getShipments() {
      shipmentService
        .paginateByUser(this.filter)
        .then(response => {
          this.shipments = response.data.data;
          this.loading =false;
          // console.log(this.shipments);
        })
        .catch(error => {
          // console.log(error);
        });
    },
    readyToPickup(item) {
        this.$confirm(
            {
                message: `Updating order to pickup required", "Are you sure?`,
                button: {
                    no: 'No',
                    yes: 'Yes'
                },
                /**
                 * Callback Function
                 * @param {Boolean} confirm
                 */
                callback: confirm => {
                    if (confirm) {
                        let data = {
                            shipment_status: "in_dispatch",
                            shipment_ids: this.selected
                        };
                        this.update(data);
                    }
                }
            }
        )

    },
    dispatched(item) {
        this.$confirm(
            {
                message: `Updating order to dispatch", "Are you sure? `,
                button: {
                    no: 'No',
                    yes: 'Yes'
                },
                /**
                 * Callback Function
                 * @param {Boolean} confirm
                 */
                callback: confirm => {
                    if (confirm) {
                        let data = {
                            shipment_status: "dispatched",
                            shipment_ids: this.selected
                        };
                        this.update(data);
                    }
                }
            }
        )

    },
    inDelivery(item) {
        this.$confirm(
            {
                message: `Updating order to In Delivery", "Are you sure? `,
                button: {
                    no: 'No',
                    yes: 'Yes'
                },
                /**
                 * Callback Function
                 * @param {Boolean} confirm
                 */
                callback: confirm => {
                    if (confirm) {
                        let data = {
                            shipment_status: "in_delivery",
                            shipment_ids: this.selected
                        };
                        this.update(data);
                    }
                }
            }
        )

    },
    delivered(item) {
        this.$confirm(
            {
                message: `Updating order as Delivered", "Are you sure? `,
                button: {
                    no: 'No',
                    yes: 'Yes'
                },
                /**
                 * Callback Function
                 * @param {Boolean} confirm
                 */
                callback: confirm => {
                    if (confirm) {
                        let data = {
                            shipment_status: "delivered",
                            shipment_ids: this.selected
                        };
                        this.update(data);
                    }
                }
            }
        )

    },
    returned(item) {
        this.$confirm(
            {
                message: `Updating order as Returned", "Are you sure?`,
                button: {
                    no: 'No',
                    yes: 'Yes'
                },
                /**
                 * Callback Function
                 * @param {Boolean} confirm
                 */
                callback: confirm => {
                    if (confirm) {
                        let data = {
                            shipment_status: "returned",
                            shipment_ids: this.selected
                        };
                        this.update(data);
                    }
                }
            }
        )

    },
    update(data) {
      this.loading = true;
      shipmentService
        .processOrder(data)
        .then(response => {
              this.selected = [];
          this.getShipments();
        })
        .catch(error => {
          // console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
