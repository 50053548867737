import Apiservice from '../../common/api.service';
import { API_URL } from '../../common/config.js'
const apiService = Apiservice;


export default class ShipmentService {


    paginate(slug, filter, index = null, ) {
        let url = API_URL + 'user/website/' + slug + '/shipping-package';
        if (filter != null && filter != undefined && filter != "") {
            var queryString = Object.keys(filter).map((key) => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(filter[key])
            }).join('&');
            if (queryString.length != 0)
                url = url + '?' + queryString;
        }
        if (index != null)
            url = url + '?page=' + index
        return apiService.get(url);
    }

    paginateByUser(filter, index = null, ) {
        let url = API_URL + 'user/shipping-package';
        if (filter != null && filter != undefined && filter != "") {
            var queryString = Object.keys(filter).map((key) => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(filter[key])
            }).join('&');
            if (queryString.length != 0)
                url = url + '?' + queryString;
        }
        if (index != null)
            url = url + '?page=' + index
        return apiService.get(url);
    }


    create(slug, data) {
        let url = API_URL + 'user/website/' + slug + '/shipping-package'
        return apiService.post(url, data);
    }


    update(slug, id, data) {
        let url = API_URL + 'user/website/' + slug + '/shipping-package/' + id
        return apiService.put(url, data);
    }

    delete(slug, id) {
        let url = API_URL + 'user/website/' + slug + '/shipping-package/' + id
        return apiService.delete(url);
    }
    show(slug, id) {
        let url = API_URL + 'user/website/' + slug + '/shipping-package/' + id
        return apiService.get(url);
    }

    validateShippingTrackingCode(slug, code) {
        let url = API_URL + 'user/website/' + slug + '/shipping-package/validate/' + code
        return apiService.get(url);
    }

    getByOrderId(slug, orderId) {
        let url = API_URL + 'user/website/' + slug + '/order/' + orderId + '/shipping-package'
        return apiService.get(url);
    }

    processOrder(data){
        let url = API_URL + 'user/shipping-package/process-order'
        return apiService.post(url, data);
    }




}
